<template>
  <div class="col-12 c g">
    <div class="row">
        <div class="col-12 col-lg-6 c">
            <br>
            <div class="card card-body text-center">
                <h3>
                    <img :src="arr.logo ? arr.logo : logo" style="width: 40px; height: 40px; object-fit: cover; border-radius: 4px !important">
                    {{ arr.schoolname }}
                </h3>
                <h5>
                    📅
                    خطة التعلم الاسبوعي للطالب {{ arr.student_name }}
                </h5>
            </div>
        </div>
    </div>
    <div v-for="plan in arr.arr" :key="plan._id" class="card card-body">
        <p>
            <i class="fa fa-user"></i> {{ plan.teacher }}
        </p>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-bordered table-hover bg-white" style="margin-top: 5px">
                <thead>
                    <th>
                        المادة
                    </th>
                    <th></th>
                    <th>
                        الأحد
                        <br>
                        <span>{{ plan.data[0].days['1'] }}</span>
                    </th>
                    <th>
                        الاثنين
                        <br>
                        <span>{{ plan.data[0].days['2'] }}</span>
                    </th>
                    <th>
                        الثلاثاء
                        <br>
                        <span>{{ plan.data[0].days['3'] }}</span>
                    </th>
                    <th>
                        الاربعاء
                        <br>
                        <span>{{ plan.data[0].days['4'] }}</span>
                    </th>
                    <th>
                        الخميس 
                        <br>
                        <span>{{ plan.data[0].days['5'] }}</span>
                    </th>
                </thead>
                <tbody>
                    <template v-for="(subject) in plan.data[0].subjects">
                        <tr :key="subject.name">
                            <td rowspan="3" class="text-center"><br>
                                {{ subject.name }}
                            </td>
                            <td style="text-align: center">الدرس</td>
                            <td >
                                <span>{{ subject.days[1].title }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[2].title }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[3].title }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[4].title }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[5].title }}</span>
                            </td>
                        </tr>
                        <tr :key="subject.name + '1'">
                            <td style="text-align: center">الاهداف</td>
                            <td >
                                <span>{{ subject.days[1].target }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[2].target }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[3].target }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[4].target }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[5].target }}</span>
                            </td>
                        </tr>
                        <tr :key="subject.name + '2'">
                            <td style="text-align: center">الواجبات</td>
                            <td >
                                <span>{{ subject.days[1].homework }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[2].homework }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[3].homework }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[4].homework }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[5].homework }}</span>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-12 text-center text-muted">
        الخدمة مقدمة من نظام <a href="https://tahdir.net" target="_blank">التحضير الذكي</a>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox, BFormRadio, BFormGroup } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
        BFormRadio,
        BFormGroup
    },
    directives: {
        Ripple,
    },
    data(){
        return {
            logo: require('@/assets/images/school.png'),
            number: window.location.href.split("student/")[1],
            id: window.location.href.split("weekplan/")[1].split("/")[0],
            arr: [],
        }
    },
    created(){
        var g = this;
        $.post(api + '/general/weekplan', {
            number: this.number,
            id: this.id
        }).then(function(r){
            if(r.status != 100){
                alert(r.response, 200)
            }else{
                g.arr = r.response;
            }
        }).fail(function(){
            alert("حدث خطأ في الاتصال", 200);
        })
    },
}
</script>

<style>
table, table *{
    white-space: nowrap;
}
table textarea{
    min-width: 200px;
}
</style>