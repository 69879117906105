import { render, staticRenderFns } from "./weekplan.vue?vue&type=template&id=c2ed9284&"
import script from "./weekplan.vue?vue&type=script&lang=js&"
export * from "./weekplan.vue?vue&type=script&lang=js&"
import style0 from "./weekplan.vue?vue&type=style&index=0&id=c2ed9284&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports